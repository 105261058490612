
import $, { Cash } from 'cash-dom'
import { IndexDB } from './tables/IndexDB'
import { getTextColor, colorToHex } from './colors'
import { makeFavicon, noteToHtml } from './helpers'
import { StoredTab } from './tables/types'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { h } from './html'

const DEFAULT_IMG = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D'

export class Configurations {
  protected _id: string

  protected _idb: IndexDB

  protected _stored: StoredTab[] = []

  protected _active: StoredTab|null = null

  protected $area: Cash

  protected $select: Cash

  protected $info: Cash

  constructor() {
    this.$area   = $('#stored-configs-area')
    this.$info   = $('#stored-config-detail')
    this.$select = $('#stored-config-list')

    this.$area.find('.btn-delete-config').on('click',
      async () => await this._onDeleteConfig())

    this.$area.find('.btn-use-config').on('click',
      async () => await this._onUseConfig())

    this.$select.on('change', () => this._onSelectChange())
  }

  async ready(idb: IndexDB): Promise<void> {
    this._idb = idb

    await this.update()
  }

  setCurrentId(id: string): void {
    this._id = id
  }

  async update(): Promise<void> {
    this._stored = (await this._idb.retrieveAllTabs()).filter(v => v.id !== this._id)

    const opts = await this.buildOptions()
    this.$select.html('')
    opts.forEach(opt => this.$select.append(opt))

    if (this._stored.length > 0) {
      this.$area.removeClass('hidden')
    } else if (!this.$area.hasClass('hidden')) {
      this.$area.addClass('hidden')
    }
  }

  async buildOptions(): Promise<HTMLElement[]> {
    const opts = [
      <option value="">Select a Configuration</option>
    ]

    this._stored.forEach((doc) => {
      opts.push(
        <option value={doc.id}>{doc.id} - {doc.title}</option>
      )
    })

    return opts
  }

  protected async _onSelectChange(): Promise<void> {
    const id  = this.$select.val() as string

    if (id === '') {
      this._active = null
      this.$info.addClass('hidden')

      $('#stored-title').text('')
      $('#stored-notes').text('')
      $('#stored-icon-img').attr('src', DEFAULT_IMG)
      $('#stored-icon-details').html('')
      return
    }

    const doc = await this._idb.retrieveStoredTab(id)

    if (doc === undefined || doc === null) {
      this._active = null
      await this.update()
      return
    }

    this._active = doc

    this.$info.removeClass('hidden')

    $('#stored-title').text(doc.title)
    $('#stored-notes').html( noteToHtml(doc.notes) )

    const ico = makeFavicon(doc.color, doc.char, doc.shape)

    $('#stored-icon-img').attr('src', ico)

    const txtColor = colorToHex(getTextColor(doc.color))
    const bgColor  = colorToHex(doc.color)
    const style    = `color: ${txtColor}; background: ${bgColor}`

    const details = [
      <p><strong>Color: </strong><span style={ style }>{ bgColor }</span></p>,
      <p><strong>Char:  </strong> { doc.char }</p>,
      <p><strong>Shape: </strong> { doc.shape }</p>,
    ]

    const $dets = $('#stored-icon-details')
    $dets.html('')
    details.forEach(det => $dets.append(det))
  }

  async _onDeleteConfig(): Promise<void> {
    if (!this._active) {
      return
    }

    await this._idb.deleteTab(this._active.id)
    await this.update()
    await this._onSelectChange()
    this._active = null
  }

  async _onUseConfig(): Promise<void> {
    if (!this._active) {
      return
    }

    window.location.hash = 'tid=' + this._active.id
    window.location.reload()
  }
}
