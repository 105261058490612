
type scalar = number | string | null | undefined
type innerValue = scalar | HTMLElement | innerValue[]

function toString(inp: scalar): string {
  if (inp === null) {
    return ''
  }

  switch (typeof inp) {
    case 'string':
      return inp
    case 'number':
      return inp.toString()
    default:
      return ''
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isObject(inp: any): inp is object {
  return (typeof inp === 'object' && inp !== undefined && inp !== null)
}

function translateAttr(name: string): string {
  switch (name) {
    case 'className': return 'class'
    default: return name
  }
}

function handleInner(ele: HTMLElement, inner: innerValue[]): HTMLElement {
  inner.forEach((val) => {
    if (val instanceof HTMLElement) {
      ele.appendChild(val)
    } else if (val instanceof Array) {
      ele = handleInner(ele, val)
    } else {
      const node = document.createTextNode(toString(val))
      ele.appendChild(node)
    }
  })

  return ele
}

export function h(eleName: string, attrs?: Record<string, scalar>, ...inner: innerValue[]): HTMLElement {
  // console.group('h', eleName)
  // console.log('attrs', attrs)
  // console.log('inner', inner)
  // console.groupEnd()

  let ele = document.createElement(eleName)

  if (isObject(attrs) && Object.keys(attrs).length > 0) {
    Object.keys(attrs).forEach((key) => {
      ele.setAttribute(translateAttr(key), toString(attrs[key]))
    })
  }

  if (inner.length > 0) {
    ele = handleInner(ele, inner)
  }

  return ele
}
