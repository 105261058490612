import { getTextColor, ColorType, colorToCss } from './colors'
import { ShapeType } from './types'
import { failSwitch } from './shared_types'

export function debounce(fn: () => void, time: number): () => void {
  let handle: number

  return function (...params): void {
    if (handle !== undefined) {
      clearTimeout(handle)
    }

    handle = window.setTimeout( () => fn.apply(this, ...params), time )
  }
}

function r(n: number): number {
  return Math.round(n * 1000) / 1000
}

function drawPolygon(ctx: CanvasRenderingContext2D,
  strokeStyle: string, size: number, sides: number, shift?: number): void {

  // radians
  const step  = 2 * Math.PI / sides
  size -= 1

  // degrees
  if (shift) {
    shift *= (Math.PI / 180)
  } else {
    shift = (Math.PI / 180)
  }

  ctx.beginPath()

  const cos = Math.cos
  const sin = Math.sin

  // The cos/sin and this entire setup is used to generate points
  // on a circle that correspond to a specific polygon (sides/step)
  // https://www.desmos.com/calculator/ozyj0ctkni
  for (let w = 0; w <= sides; w++) {
    const q = w * step + shift

    ctx.lineTo(
      // x = m(q) + b
      /* x = */ r((size * cos(q)) + size + 1),
      // y = m(q) + b
      /* y = */ r((size * sin(q)) + size + 1),
    )
  }

  ctx.fill()

  ctx.strokeStyle = strokeStyle
  ctx.lineWidth = 3
  ctx.beginPath()

  for (let q = 0; q <= sides; q++) {
    const curr = q * step + shift

    ctx.lineTo(
      r(size + (size * Math.cos(curr))) + 1,
      r(size + (size * Math.sin(curr))) + 1,
    )
  }

  ctx.stroke()
}

function drawCharacter(ctx: CanvasRenderingContext2D, textFill: string,
  chr: string, font: string, halfSize: number): void {

  ctx.font = `54px ${font}`

  ctx.fillStyle    = textFill
  ctx.textAlign    = 'center'
  ctx.textBaseline = 'middle'

  const isHanging = 'gjpqty'.indexOf(chr) >= 0

  ctx.fillText(chr, halfSize + 1,
    halfSize + 4 - (isHanging ? 6 : 0))
}

/**
 *
 * @param chr {string} The character in question
 * @returns {number} The x offset to better center the character.
 */
function circleXOffset(chr: string): number {
  switch (chr) {
    case 'D':
    case 'B':
    case 'P':
      return 3
    case 'E':
    case 'K':
    case 'M':
    case 'R':
      return 1
    case 'U':
      return 1.5
    case 'G':
    case 'C':
    case 'O':
      return 0.5
  }

  return 1
}

export function makeFavicon(color: ColorType, chr: string, shape: ShapeType): string {
  const cnv = document.getElementById('icon-canvas') as HTMLCanvasElement
  const ctx = cnv.getContext('2d')

  if (!ctx) {
    console.error('Canvas not supported?')
    return ''
  }

  const textCol = getTextColor(color)
  textCol.alpha = 1

  const textFill = colorToCss(textCol)

  const FONT_NAME = 'Arial'
  const DIM_FULL = 64
  const DIM_HALF = DIM_FULL / 2

  cnv.width  = DIM_FULL
  cnv.height = DIM_FULL

  ctx.clearRect(0, 0, cnv.width, cnv.height)

  ctx.fillStyle = colorToCss(color)

  const isHanging = 'gjpqy'.indexOf(chr) >= 0

  switch (shape) {
    case ShapeType.circle:
      ctx.beginPath()
      ctx.arc(DIM_HALF, DIM_HALF, DIM_HALF - 2, 0, Math.PI * 2, false)
      ctx.strokeStyle = '#fff'
      ctx.lineJoin    = 'bevel'
      ctx.lineWidth   = 3
      ctx.fill()
      ctx.stroke()

      ctx.fillStyle    = textFill
      ctx.textAlign    = 'center'
      ctx.textBaseline = 'middle'
      ctx.font      = `58px ${FONT_NAME}`
      ctx.fillText(chr, DIM_HALF + circleXOffset(chr),
        DIM_HALF + 4 - (isHanging ? 8 : 0))

      break
    case ShapeType.square:
      ctx.fillRect(0, 0, cnv.width, cnv.height)
      ctx.strokeStyle = '#fff'
      ctx.lineJoin    = 'bevel'
      ctx.lineWidth   = 2
      ctx.strokeRect(1, 1, cnv.width - 2 , cnv.height - 2)

      drawCharacter(ctx, textFill, chr, FONT_NAME, DIM_HALF)

      // ctx.fillStyle = colorToCss(textCol)
      // ctx.font      = `68px ${FONT_NAME}`
      // ctx.textAlign = 'center'
      // ctx.fillText(chr, DIM_HALF, isLower ? 50 : 60)
      break
    case ShapeType.triangle:
      ctx.beginPath()

      //              x      |      y
      ctx.moveTo(DIM_HALF,                0)
      ctx.lineTo(DIM_FULL - 1, DIM_FULL - 1)
      ctx.lineTo(           1, DIM_FULL - 1)
      ctx.lineTo(DIM_HALF,                0)

      ctx.fill()

      ctx.strokeStyle = textFill
      ctx.lineWidth   = 3
      ctx.stroke()

      ctx.fillStyle = textFill

      ctx.font = `40px ${FONT_NAME}`

      if (isHanging) {
        ctx.font = `38px ${FONT_NAME}`
      }

      ctx.textAlign = 'center'
      ctx.fillText(chr, DIM_HALF + 1, isHanging ? 52 : 58)
      break
    case ShapeType.pentagon:
      drawPolygon(ctx, textFill, DIM_HALF, 5, -17)
      drawCharacter(ctx, textFill, chr, FONT_NAME, DIM_HALF)
      break
    case ShapeType.hexagon:
      drawPolygon(ctx, textFill, DIM_HALF, 6, -0.5)
      drawCharacter(ctx, textFill, chr, FONT_NAME, DIM_HALF)
      break
    case ShapeType.heptagon:
      drawPolygon(ctx, textFill, DIM_HALF, 7, -39)
      drawCharacter(ctx, textFill, chr, FONT_NAME, DIM_HALF)
      break
    case ShapeType.octagon:
      drawPolygon(ctx, textFill, DIM_HALF, 8)
      drawCharacter(ctx, textFill, chr, FONT_NAME, DIM_HALF)
      break
    // case ShapeType.nonagon:
    //   drawPolygon(ctx, textFill, DIM_HALF, 9)
    //   drawCharacter(ctx, textFill, chr, FONT_NAME, DIM_HALF)
    //   break
    // case ShapeType.decagon:
    //   drawPolygon(ctx, textFill, DIM_HALF, 10)
    //   drawCharacter(ctx, textFill, chr, FONT_NAME, DIM_HALF)
    //   break
    // case ShapeType.hendecagon:
    //   drawPolygon(ctx, textFill, DIM_HALF, 11)
    //   drawCharacter(ctx, textFill, chr, FONT_NAME, DIM_HALF)
    //   break
    // case ShapeType.dodecagon:
    //   drawPolygon(ctx, textFill, DIM_HALF, 12)
    //   drawCharacter(ctx, textFill, chr, FONT_NAME, DIM_HALF)
    //   break
    default:
      failSwitch(shape)
      break
  }

  return cnv.toDataURL('image/png')
}

export function htmlToNote(html: string): string {
  return html
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    // slight loss of data on this: we remove the \r
    .replace(/<br \/>/g, '\n')
    .replace(/<strong>(.+?)<\/strong>/g, '**$1**')
    .replace(/<em data-type="1">(.+?)<\/em>/g, '*$1*')
    .replace(/<em data-type="2">(.+?)<\/em>/g, '_$1_')
}

export function noteToHtml(note: string): string {
  return note
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/\r?\n/g, '<br />')
    .replace(/\*{2}([^\*]+)\*{2}/g, '<strong>$1</strong>')
    .replace(/\*([^\*]+)\*/g, '<em data-type="1">$1</em>')
    .replace(/\_([^_]+)\_/g, '<em data-type="2">$1</em>')
}
