
/**
 * Parts of this are based off of the work on FitText dot JS
 * by Dave Rupert: https://github.com/adactio/FitText.js/blob/master/fittext.js
 */


import $, { Cash } from 'cash-dom'
import { debounce } from './helpers'

let isListenersBound = false
let elements: Cash[] = []

type FTSettings = {
  minFontSize: number;
  maxFontSize: number;
  maxHeight:   number;
}

function r(n: number): number {
  return Math.round(n * 1000) / 1000
}

/**
 *
 * @param compressor  Compression Number. The larger it is, the more small text is
 * @param clientWidth Client Width (or width of container desired)
 * @param options     Options
 */
function calculateFontSize (compressor: number, clientWidth: number, options: FTSettings): number {
  const min = r(clientWidth / (compressor * 10))

  // console.log('min', min, 'compressor', compressor * 10, 'clientWidth', clientWidth)

  const maxOpt = Math.min(min, options.maxFontSize)

  return Math.max(maxOpt, options.minFontSize)
}

export type Dimensions = {
  width:  number;
  height: number;
}

function calculateTextDimensions(fontSize: number, text: string, maxWidth: number, maxHeight: number): Dimensions {
  // https://jsfiddle.net/xfov87e3/10/
  // these are widths determined by 'W' & 'i' and heights determined by '0' and '.'
  // averaged out between font size 6 and 500
  const ARIAL_WIDTH_RATIO  = 0.7 // 0.5831
  const ARIAL_HEIGHT_RATIO = 0.9981

  const widthInc  = Math.floor(fontSize * ARIAL_WIDTH_RATIO)
  const heightInc = Math.floor(fontSize * ARIAL_HEIGHT_RATIO)

  const widthIter = Math.floor((text.length * widthInc) / maxWidth)

  // console.log('widthInc', widthInc, 'heightInc', heightInc, 'widthIter', widthIter)

  return {
    width:  widthIter > 0 ? maxWidth : widthInc,
    height: widthIter > 0 ? widthIter * heightInc : maxHeight,
  }
}

export function resize(): void {
  // console.log('[resize] actually resize')
  elements.forEach(($el) => {
    if (!$el || !$el[0]) {
      console.error('$el isnt present but present', $el)
      return
    }

    const maxWidth = $('.container').width()

    const comp = parseFloat($el.data('compress') as string)
    const opts = $el.data('options') as FTSettings

    const txt = $el.text()

    // console.log('$el', $el)

    let size = calculateFontSize(comp, maxWidth, opts)

    // console.group('resize')
    // console.log('size predicted is', size)
    // console.log('maxHeight', opts.maxHeight)

    let dim = calculateTextDimensions(size, txt, maxWidth, opts.maxHeight)

    // console.log('dimensions', dim)

    if (dim.height > opts.maxHeight) {
      while (dim.height > opts.maxHeight) {
        size -= 2
        dim = calculateTextDimensions(size, txt, maxWidth, opts.maxHeight)
        // console.log('size', size, dim)
      }
    }

    // console.log('calculated size at', size)
    $el.css('font-size', size)
    console.groupEnd()
  })
}

const _resize = debounce(() => {
  // console.log('[resize] debounce')
  resize()
}, 500)

export function bindListeners(): void {
  // console.log('[resize] bindListeners')
  if (isListenersBound) {
    return
  }

  isListenersBound = true
  $(window).on('resize orientationchange', _resize)
}

export function resetTextFitting (): void {
  elements = []
}

export function fitText($el: Cash, compress: number, minFontSize: number, maxFontSize: number, maxHeight: number): void {
  $el.data('compress', compress)
  $el.data('options', {
    maxFontSize: maxFontSize,
    minFontSize: minFontSize,
    maxHeight:   maxHeight,
  })

  elements.push($el)
}
