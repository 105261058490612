
export function randomInt(max: number): number {
  return Math.floor( Math.random() * Math.floor(max) )
}

export function generateId(): string {
  const map  = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  const mLen = map.length

  let out = ''

  for (let i = 0; i < 5; i++) {
    out += map[ randomInt(mLen - 1) ]
  }

  return out
}
