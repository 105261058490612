
import $, { Cash } from 'cash-dom'
import { randomHSLA, colorToCss, colorToHex, hexToRGBA, relativeLuminance, makeRGBA } from './colors'
import { generateId } from './math'
import { debounce, makeFavicon, noteToHtml } from './helpers'
import { IndexDB } from './tables/IndexDB'
import { Configurations } from './Configurations'
import { ShapeType, KeyUpEv, StateChangeType, StatusType, BlankTabInformation, StrStatusType, ShapeTypeStrs, StrStateChangeType } from './types'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { h } from './html'
import { receiveWorkerMsg, WorkerMsgType, MainMsg, sendMainMsg } from './shared_types'
import { $a } from './assert'
import { StoredTab, BaseTab, MakeStoredTab } from './tables/types'
import { fitText, bindListeners, resize, resetTextFitting } from './font'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type safeAny = any

// TODO:
// X Support the status type event changes
// X Make it so fresh tabs do not immediately make a new TID and save it
// X Update the font color adaptation to not just be black/white
// X Have icon color also be page background color (darkened)
// X Update textual location on icons
// ? Add animate.css
// ? Set means for a "default tab" settings to be customizable
//   (default: true, tid=default?, readonly: true)
// ? Setup timestamping on TABs
// X Expand notes to support newlines, and expand better.
// X Restrict TID retrieval to certain charset ([a-zA-Z0-9-.]{,30})

class Main {
  protected _idb: IndexDB

  protected _throttledStateUpdate: () => void

  protected _configurations: Configurations

  protected _status: StatusType = StatusType.fresh

  protected _id: string

  protected _tab: BaseTab = BlankTabInformation()

  protected _prevMode: ('edit' | 'view' | '') = ''

  protected _registrar: ServiceWorkerRegistration

  protected $body: Cash

  protected $title: Cash

  protected $color: Cash

  protected $char: Cash

  protected $shape: Cash

  protected $notes: Cash

  protected $favicon: Cash

  protected $configs: Cash

  protected $viewTitle: Cash

  protected $viewNotes: Cash

  protected $editArea: Cash

  protected $randColor: Cash

  protected $viewArea: Cash

  protected $viewToggle: Cash

  constructor() {
    // ServiceWorker: our means of communicating between all TabHomes
    this.registerServiceWorker()

    // Elements: our $cash('.query') registrations and this.element() ones
    this.registerElements()

    // Events outside of the purview of native stateChange
    this.registerEvents()

    // Handlers (instantiation of things)
    this.registerHandlers()

    // Once IDB is ready, we launch.
    this._idb.ready(async () => {
      await this.bootstrap()
      await this._configurations.ready(this._idb)
    })
  }

  private registerHandlers(): void {
    window['$'] = $

    this._idb = new IndexDB()
    this._configurations = new Configurations()
    this._throttledStateUpdate = debounce(async () => {
      // console.log('[_throttledStateUpdate]')

      this.sendWorkerMessage({
        type: 'tabChange',
        dir:  'both',
        id:   this.getID(),
        doc:  this._tab,
      })

      await this.idbStore()
    }, 800)
  }

  private registerElements(): void {
    this.$title = this.element('#title')
    this.$notes = this.element('#notes')
    this.$color = this.element('#ico-color')
    this.$shape = this.element('#ico-shape')
    this.$char = this.element('#ico-char')

    this.$body = $('body')

    this.$favicon = $('.favicon')

    this.$editArea = $('#tab-edit')
    this.$viewArea = $('#tab-view')

    this.$viewTitle  = this.$viewArea.find('.tab-view-title')
    this.$viewNotes  = this.$viewArea.find('.tab-view-notes')
    this.$viewToggle = $('#toggle-view')
    this.$randColor  = $('#color-random')

    ShapeTypeStrs().forEach((shape) => {
      this.$shape.append(
        <option value={shape}>{shape.charAt(0).toUpperCase() + shape.substr(1)}</option>
      )
    })
  }

  registerFitText(): void {
    resetTextFitting()

    const split = (window.innerHeight / 3) - 150
    fitText(this.$viewTitle, 0.4, 16, 400, split * 2)
    fitText(this.$viewNotes, 3, 16, 300, split)
  }

  private registerEvents(): void {
    bindListeners()

    this.registerFitText()

    const registerDebounced = debounce(() => {
      this.registerFitText()
      resize()
    }, 300)

    $(window).on('resize orientationchange', registerDebounced)

    this.$viewToggle.on('click', async () => {
      this._tab.mode = (this._tab.mode === 'view' ? 'edit' : 'view')
      await this.stateChange(StateChangeType.input)
    })

    this.$randColor.on('click', async () => {
      this._tab.color = randomHSLA()
      this.$color.val(colorToHex(this._tab.color))

      await this.stateChange(StateChangeType.input)
    })

    $('.btn-privacy-policy').on('click', () => {
      const $policy = $('.privacy-policy')

      if (!$policy.data('toggled')) {
        $policy.data('toggled', '0')
      }

      if ($policy.data('toggled') === '1') {
        $policy.data('toggled', '0')
        $policy.addClass('hidden')
      } else {
        $policy.data('toggled', '1')
        $policy.removeClass('hidden')
        if ($policy[0]) {
          $policy[0].focus()
        }
      }
    })
  }

  element(selector: string): Cash {
    const $out = $(selector)

    $out.data('dirty', false)

    const handleChange = async (): Promise<void> => {
      $out.data('dirty', true)
      await this.stateChange(StateChangeType.input)
    }

    const handleFastChange = async (): Promise<void> => {
      $out.data('dirty', true)
      await this.stateChange(StateChangeType.fastInput)
    }

    switch ($out.prop('nodeName')) {
      case 'INPUT':
        const type = $out.prop('type').toUpperCase()

        if (type === 'TEXT') {
          $out.on('keyup', async (ev: KeyUpEv) => {
            if (ev.altKey || ev.ctrlKey || ev.shiftKey || ev.metaKey) {
              return
            }

            handleFastChange()
          })
        } else if (type === 'COLOR') {
          $out.on('input',  handleChange)
          $out.on('change', handleChange)
        } else {
          $out.on('change', handleChange)
        }

        break
      case 'TEXTAREA':
        $out.on('keyup', handleFastChange)
        break
      case 'SELECT':
        $out.on('change', handleChange)
        break
      default:
        console.error('Unsupported element', $out.prop('nodeName'))
        break
    }

    return $out
  }

  inStatuses(status: StatusType, ...statuses: StatusType[]): boolean {
    if (statuses.length === 0) {
      return false
    }

    for (const idx in statuses) {
      if (status === statuses[idx]) {
        return true
      }
    }

    return false
  }

  inChanges(change: StateChangeType, ...changes: StateChangeType[]): boolean {
    if (changes.length === 0) {
      return false
    }

    for (const idx in changes) {
      if (change === changes[idx]) {
        return true
      }
    }

    return false
  }

  tabValueToString(name: keyof StoredTab, state: BaseTab): string {
    switch (name) {
      case 'color':
        return colorToHex(state[name])
      case 'shape':
        return state[name]
      case 'char':
      case 'mode':
      case 'notes':
      case 'title':
        return state[name]
      default:
        return ''
    }
  }

  async stateChange(change: StateChangeType, status?: StatusType): Promise<void> {
    if (status) {
      this._status = status
    }

    // console.trace('stateChange')
    // console.log('stateChange', 'change=', StrStateChangeType(change), 'status', status ? StrStatusType(status) : StrStatusType(this._status))

    $('input,select,textarea').each((idx, ele) => {
      const $ele    = $(ele)
      const name    = $ele.attr('name') as string
      const isDirty = $ele.data('dirty') as boolean

      let val: string = $ele.val() as string

      if (isDirty) {
        $ele.data('dirty', false)
      } else if (this.inChanges(change, StateChangeType.database, StateChangeType.init)
                && typeof this._tab[name] !== 'undefined') {

        const stateVal = this.tabValueToString(name as keyof StoredTab, this._tab)

        if (stateVal === undefined) {
          console.error(`[stateChange] stateVal of ${name} is undefined. here is the tab:`, this._tab)
        } else if (stateVal != val) {
          // console.log('retrieving from state', this._doc[name], stateVal)

          $ele.val(stateVal)
          val = stateVal
        }
      }

      switch (name) {
        case 'title':
          this._tab.title = val
          document.title  = val
          break
        case 'notes':
          this._tab.notes = val
          this.updateFavicon()
          break
        case 'color':
          this._tab.color = hexToRGBA(val)
          this.updateFavicon()
          this.updateBackground()
          break
        case 'char':
          this._tab.char = val
          this.updateFavicon()
          break
        case 'shape':
          this._tab.shape = val as ShapeType
          this.updateFavicon()
          break
      }
    })

    if (this.inChanges(change, StateChangeType.input, StateChangeType.fastInput)) {
      // console.log('change', StrStateChangeType(change))
      // console.log('status', StrStatusType(this._status))

      // We're a brand new tab with nothing to worry about let's commit
      // commitment is important.
      if (this._status === StatusType.fresh) {
        const id = this.setID(generateId(), true)

        await this.idbStore()
        this._status = StatusType.saved

        this.sendWorkerMessage({
          type: 'init',
          dir:  'worker',
          id:   id,
        })

      }
      // we're not a new: we're either a saved or updated tab, so we update
      else if (this.inStatuses(this._status, StatusType.saved, StatusType.updated)) {
        // console.log('[stateChange] -> [_throttleStateUpdate]', StrStatusType(this._status))
        this._throttledStateUpdate()
      } else {
        // console.log('[stateChange] we do nothing, status is', StrStatusType(this._status))
      }
    }

    if (this._tab.mode !== this._prevMode) {
      this._prevMode = this._tab.mode
      this.updateView()
    }
  }

  async idbStore(): Promise<void> {
    await this._idb.storeTab( MakeStoredTab(this._id, this._tab) )
      .catch((err) => console.error('error attempting to store:', err))
  }

  protected setID(id: string, updateHash: boolean): string {
    // console.trace('this.setID()', id)
    // console.log('setID()', id)

    this._id = id
    this._configurations.setCurrentId(id)

    if (updateHash) {
      window.location.hash = 'tid=' + id
    }

    return id
  }

  protected getID(): string {
    return this._id
  }

  async bootstrap(): Promise<void> {
    $a('ID should be undefined', typeof this._id === 'undefined')

    // lets retrieve the hash for this instance
    const hash  = window.location.hash
    const match = hash.match(/tid=([a-zA-Z0-9-.]{4,30})/)

    // console.log('hash', hash, 'match', match)

    if (match && match[1]) {
      const id = match[1]
      const tab = await this._idb.retrieveStoredTab(id)

      // console.log('id', id, 'tab', tab)

      if (tab === undefined || tab === null) {
        await this.stateChange(StateChangeType.init, StatusType.deleted)
        return
      }

      this._tab = tab
      this.setID(id, false)

      this.sendWorkerMessage({
        type: 'init',
        dir:  'worker',
        id:   id,
      })

      await this.stateChange(StateChangeType.init, StatusType.saved)
    } else {
      await this.stateChange(StateChangeType.init, StatusType.fresh)
    }
  }

  registerServiceWorker(): void {
    if (!('serviceWorker' in navigator)) {
      console.error('browser does not support service workers, wtf')
      return
    }

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      location.reload()
    })

    navigator.serviceWorker.addEventListener('message', (ev) => {
      this._onWorkerMessage(ev)
    })

    navigator.serviceWorker.register('/worker.js', { scope: '/' }).then((reg: ServiceWorkerRegistration) => {
      this._registrar = reg

      this.logWorker('[register] base', reg)

      if (reg.installing) {
        reg.installing.addEventListener('statechange', async () => {
          this.logWorker('[register] state change', reg)
          console.log('worker registration', reg.installing?.state)

          if (reg.active?.state === 'activated') {
            console.log('state', this._tab)

            this.$body.find('.fe-area-wrapper').show()
          }
        })
      }

      reg.addEventListener('updatefound', () => {
        console.log('[register] update found')
        this.logWorker('updateFound', reg)
        reg.update()
      })
    }).catch((err) => console.error('ServiceWorker failed: ' + err))

    navigator.serviceWorker.ready.then(async (reg: ServiceWorkerRegistration) => {
      reg.addEventListener('updatefound', () => {
        console.log('[ready] update found')
        this.logWorker('ready updateFound', reg)
        reg.update()
      })

      this.logWorker('[ready] ready', reg)
    })
  }

  logWorker(ev: string, reg: ServiceWorkerRegistration): void {
    console.group('[worker->page] ' + ev)
    console.log('installing', reg.installing?.state, reg.installing)
    console.log('waiting',    reg.waiting?.state,    reg.waiting)
    console.log('active',     reg.active?.state,     reg.active)
    console.groupEnd()
  }

  sendWorkerMessage(msg: MainMsg): void {
    if (!navigator.serviceWorker.controller) {
      return
    }

    sendMainMsg(navigator.serviceWorker.controller, msg)
  }

  _onWorkerMessage(ev: MessageEvent): void {
    const msg = receiveWorkerMsg(ev.data)

    if (msg === null) {
      return
    }

    msg.type = msg.type as WorkerMsgType

    switch (msg.type) {
      case 'log':
        console.log('[serviceWorker]', ...msg.logs)
        break
      case 'refresh':
        break
      case 'tabChange':
        if (this.getID() === msg.id) {
          console.log('[serviceWorker] state change, new state:', this._tab)
          this._tab = msg.doc
          this.stateChange(StateChangeType.database, StatusType.updated)
        }

        break
      default:
        break
    }
  }

  updateView(): void {
    // console.log('[updateView]', this._tab.mode)

    this.$body
      .removeClass('mode--view')
      .removeClass('mode--edit')

    switch (this._tab.mode) {
      case 'view':
        this.$body.addClass('mode--view')

        this.$viewToggle.text('Switch to Edit Mode')
        break
      case 'edit':
        this.$body.addClass('mode--edit')

        this.$viewToggle.text('Switch to Big Mode')
        break
      default:
        break
    }

    this.$viewTitle.text(this._tab.title)
    this.$viewNotes.html( noteToHtml(this._tab.notes) )

    $('#main').removeAttr('hidden')
    resize()
  }

  updateBackground(): void {
    const relLum  = relativeLuminance(this._tab.color)
    const isLight = relLum > 0.179
    // todo darken significantly
    this.$body.css('background', colorToCss(this._tab.color))

    // $('.debug-lum').text(relLum.toFixed(5) + ' isLight? ' + (isLight ? 'YES' : 'NO'))

    const $btnImg = this.$randColor.find('img')
    $btnImg.attr('src', $btnImg.data(isLight ? 'light' : 'dark'))
    // console.log('$btnImg', $btnImg, $btnImg.attr('src'))

    this.$body
      .removeClass('mode--light')
      .removeClass('mode--dark')
      .addClass( isLight ? 'mode--light' : 'mode--dark' )
  }

  updateFavicon(): void {
    const b64 = makeFavicon(this._tab.color, this._tab.char, this._tab.shape as ShapeType)

    this.$favicon.each((idx, el: HTMLLinkElement) => {
      el.href = b64
    })
  }

  generateDebugFavicons(): void {
    const bet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890'.split('')
    const shapes = [
      ShapeType.circle,
      ShapeType.triangle,
      ShapeType.square,
    ]

    const color = makeRGBA(0, 0, 255)

    $('.debug-area').on('click', 'img', (ev: Event) => {
      const img = ev.target as HTMLImageElement

      $('.favicon').attr('href', img.src)
    })

    const $large = $('.debug-area .large-img')
    const $small = $('.debug-area .small-img')

    ShapeTypeStrs().forEach(shape => {
      const img = makeFavicon(color, 'A', shape)
      const $img = $('<img/>')
      $img.attr('src', img)
      $large.append($img)

      const $img2 = $('<img/>')
      $img2.attr('src', img)
        .attr('width', '32')
        .attr('height', '32')

      $small.append($img2)
    })

    shapes.forEach((shape) => {
      bet.forEach((chr) => {
        const img = makeFavicon(color, chr, shape)

        const $img = $('<img/>')
        $img.attr('src', img)
        $large.append($img)

        const $img2 = $('<img/>')
        $img2.attr('src', img)
          .attr('width', '32')
          .attr('height', '32')

        $small.append($img2)
      })
    })
  }
}

$(() => new Main())
