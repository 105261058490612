import { StoredTab, BaseTab } from './tables/types'

export type WorkerMsgType =
  'log' |
  'refresh' |
  'tabChange'

export type MainMsgType =
  'init' |
  'tabChange'

type MsgType = WorkerMsgType | MainMsgType

type MsgDir  = 'worker' | 'main' | 'both';

export type WorkerMsg =
  LogMsg |
  RefreshMsg |
  TabChangeMsg

export type MainMsg =
  InitMsg |
  TabChangeMsg

type Msg = WorkerMsg | MainMsg

type BaseMsg = {
  type: string;
  dir:  MsgDir;
}

export type InitMsg = BaseMsg & {
  type: 'init';
  dir:  'worker';
  id:   string;
}

export type LogMsg = BaseMsg & {
  type: 'log';
  dir:  'main';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logs: any[];
}

export type RefreshMsg = BaseMsg & {
  type: 'refresh';
  dir:  'main';
  url:  string;
  eTag: string|undefined;
}

export type TabChangeMsg = BaseMsg & {
  type: 'tabChange';
  dir:  'both';
  id:   string;
  doc:  BaseTab;
}

export interface Postable {
  postMessage(data: string): void;
}

export function isValidStoredTab(doc: StoredTab): boolean {
  if (!doc.id || !doc.title || !doc.shape || !doc.color) {
    return false
  }

  return true
}

function sendMessage(rel: Postable, msg: Msg): void {
  rel.postMessage( JSON.stringify(msg) )
}

export function sendWorkerMsg(rel: Postable, msg: WorkerMsg): void {
  sendMessage(rel, msg)
}

export function sendMainMsg(rel: Postable, msg: MainMsg): void {
  sendMessage(rel, msg)
}

export function failSwitch(i: never): void {
  i
  throw new TypeError('failSwitch for ' + i)
}

export function receiveMessage(raw: string): Msg|null {
  if (!raw || !raw.length) {
    return null
  }

  console.log('receiveMessage', raw)

  let parsed: any

  try {
    parsed = JSON.parse(raw)
  } catch (err) {
    console.error('failed to parse', raw)
    return null
  }

  if (!parsed.type || typeof parsed.type !== 'string') {
    return null
  }

  const parsedType: MsgType = parsed.type

  switch (parsedType) {
    case 'init':
      return parsed as InitMsg
    case 'log':
      return parsed as LogMsg
    case 'refresh':
      return parsed as RefreshMsg
    case 'tabChange':
      return parsed as TabChangeMsg
    default:
      if (parsedType == 'stateChange') {
        const output = parsed as TabChangeMsg
        output.type = 'tabChange'
        return output
      } else {
        failSwitch(parsedType)
      }

  }

  return null
}

export function receiveWorkerMsg(raw: string): WorkerMsg|null {
  return receiveMessage(raw) as (WorkerMsg|null)
}

export function receiveMainMsg(raw: string): MainMsg|null {
  return receiveMessage(raw) as (MainMsg|null)
}
