import { makeRGBA } from './colors'
import { BaseTab } from './tables/types'

export enum ShapeType {
  circle     = 'circle',
  triangle   = 'triangle',
  square     = 'square',
  pentagon   = 'pentagon',
  hexagon    = 'hexagon',
  heptagon   = 'heptagon',
  octagon    = 'octagon',
  // nonagon    = 'nonagon',
  // decagon    = 'decagon',
  // hendecagon = 'hendecagon',
  // dodecagon  = 'dodecagon',
}

const ShapeTypesMap = new Map<string, ShapeType>()
Object.keys(ShapeType).forEach((shape: ShapeType) => {
  const shapeVal: string = ShapeType[shape]
  ShapeTypesMap.set(shapeVal, shape)
})

export function ShapeTypes(): ShapeType[] {
  const out: ShapeType[] = []

  for (const idx in ShapeTypesMap) {
    out.push(ShapeTypesMap[idx])
  }

  return out
}

export function ShapeTypeStrs(): Map<string, ShapeType> {
  return ShapeTypesMap
}

export type Shape = typeof ShapeType

export enum StatusType {
  /**
   * Fresh: unmodified state where nothing has
   * changed yet. This state is where an ID
   * might be generated but is not yet stored
   * anywhere.
   */
  fresh   = 0,
  /**
   * Saved: the state with which things are saved,
   * and are handled in the IndexDB
   */
  saved   = 1,
  /**
   * Updated: When a worker from another tab updates
   * the results for this tab. Should be a transitional
   * and temporary state (updated -> saved)
   */
  updated = 2,
  /**
   * Deleted: when the worker from another tab deletes
   * this particular tab (deleted -> fresh)
   */
  deleted = 3,
}

export function StrStatusType(st: StatusType): string {
  return {
    0: 'fresh',
    1: 'saved',
    2: 'updated',
    3: 'deleted',
  }[st] || 'unknown'
}

export function isNotSCT(stc: StateChangeType, ...types: StateChangeType[]): boolean {
  for (const t in types) {
    if (types[t] == stc) {
      return false
    }
  }

  return true
}

export enum StateChangeType {
  /**
   * init: State changes in this state mode do not
   * propgate to the store
   */
  init = 0,
  /**
   * input: state changes from the user input
   */
  input = 1,
  /**
   * fastInput: input which will require a debounce delay in achieving stonks
   */
  fastInput = 2,
  /**
   * database: state changes from the database
   */
  database = 3,
}

export function StrStateChangeType(sct: StateChangeType): string {
  return {
    0: 'init',
    1: 'input',
    2: 'fastInput',
    3: 'database',
  }[sct] || 'unknown'
}

export function BlankTabInformation(): BaseTab {
  return {
    title: 'Tab Home',
    notes: '',
    color: makeRGBA(50, 50, 50, 1),
    char:  'T',
    shape: ShapeType.circle,
    mode:  'edit',
  }
}

export type KeyUpEv = KeyboardEvent & { target: HTMLInputElement|HTMLTextAreaElement };
export type InputEv = InputEvent & { target: HTMLInputElement|HTMLTextAreaElement };
