import { ShapeType } from '../types'
import { ColorType } from '../colors'

export type BaseTab = {
  title:   string;
  notes:   string;
  char:    string;
  color:   ColorType;
  shape:   ShapeType;
  mode:    'view' | 'edit';
}

export type StoredTab = BaseTab & {
  id:      string;
  default: boolean;
}

export function MakeStoredTab(id: string, tab: BaseTab): StoredTab {
  const coerced = tab as StoredTab
  coerced.id = id

  return coerced
}

export type StoredConfigItem = {
  name:  string;
  value: any;
}

export type GenericStoredItem = StoredTab | StoredConfigItem

export type IDBResultType = {
  target: {
    result: IDBDatabase;
  };
}

export type IDBRetrieveType = {
  target: {
    result: StoredTab;
  };
}

export type IDBRetreveAllType = {
  target: {
    result: StoredTab[];
  };
}
export type IDBSuccessEv  = Event & IDBResultType;
export type IDBRetrieveEv = Event & IDBRetrieveType;
export type IDBRetrieveAllEv = Event & IDBRetreveAllType;
