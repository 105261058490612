
export function $a (str: string, test: boolean): void {
  if (test) {
    return
  }

  console.groupCollapsed('ASSERT FAILED:', str)
  console.trace()
  console.groupEnd()
}
