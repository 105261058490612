import { StoredTab, IDBRetrieveEv, IDBRetrieveAllEv } from './types'
import { isValidStoredTab } from '../shared_types'

const dbName = 'tabhome.app'
const docName = 'tabs'

type IndexDBOpenRequestEv = Event & { target: { result: IDBDatabase } }

export class IndexDB {
  protected _request: IDBOpenDBRequest
  protected _db: IDBDatabase

  protected _onReady: ((idb: IndexDB) => void)[] = []

  constructor() {
    this._request = window.indexedDB.open(dbName, 1)

    this._request.onerror = (ev): void => {
      this._onRequestError(ev)
    }

    this._request.onsuccess = (ev: IndexDBOpenRequestEv): void => {
      this._onRequestSuccess(ev)
    }

    this._request.onupgradeneeded = (): void => {
      this._onUpgradeNeeded()
    }
  }

  protected _onUpgradeNeeded(): void {
    this._db = this._request.result

    const store = this._db.createObjectStore(docName, { keyPath: 'id' })
    store.createIndex('id', 'id', { unique: true })

    // const config = this._db.createObjectStore(configName, { keyPath: 'name' })
    // config.createIndex('name', 'name', { unique: true })
  }

  protected _onRequestSuccess(ev: IndexDBOpenRequestEv): void {
    this._db = ev.target.result
    this._onReady.forEach((cb) => cb(this))
  }

  protected _onRequestError(ev: Event): void {
    console.error('error', ev)
  }

  ready(cb: (idb: IndexDB) => void): void {
    this._onReady.push(cb)
  }

  _begin(name: string, prp: string, rw: boolean): IDBObjectStore {
    const trx = this._db.transaction([name], rw ? 'readwrite' : 'readonly')

    trx.onerror = (err): void => console.error('trx error for', prp, err)

    return trx.objectStore(name)
  }

  // async _store(name: string, data: any): Promise<void> {

  // }

  async storeTab(doc: StoredTab): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this._db) {
        reject('attempting to retrieve before ready')
        return
      }

      if (!isValidStoredTab(doc)) {
        reject('document is not considered valid')
        return
      }

      const store = this._begin(docName, 'store', true)
      const req   = store.put(doc)

      req.onsuccess = (): void => resolve()
      req.onerror   = (err): void => reject(err)
    })
  }

  async retrieveStoredTab(id: string): Promise<StoredTab|null> {
    return new Promise((resolve, reject) => {
      if (!this._db) {
        reject('cannot retrieve while database is not ready')
        return
      }

      const store = this._begin(docName, 'retrieve', false)
      const req   = store.get(id)

      req.onsuccess = (ev: IDBRetrieveEv): void => {
        resolve(ev.target.result)
      }

      req.onerror = (err): void => {
        reject(err)
      }
    })
  }

  async retrieveAllTabs(): Promise<StoredTab[]> {
    return new Promise((resolve, reject) => {
      if (!this._db) {
        reject('attempting to retrieve before ready')
        return
      }

      const store = this._begin(docName, 'retrieveAll', false)
      const req   = store.getAll()
      req.onsuccess = (ev: IDBRetrieveAllEv): void => {
        resolve(ev.target.result)
      }

      req.onerror = (ev): void => {
        console.error('onerror', req, ev)
        reject(ev)
      }
    })
  }

  async deleteTab(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this._db) {
        reject('attempting to delete before ready')
        return
      }

      const store = this._begin(docName, 'delete', true)
      const req   = store.delete(id)

      req.onsuccess = (): void => {
        resolve()
      }

      req.onerror = (err): void => {
        reject(err)
      }
    })
  }
}
