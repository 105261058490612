import { randomInt } from './math'

export type ColorType = ColorRGBA | ColorHSLA

export type ColorHSLA = {
  hue:        number;
  saturation: number;
  lightness:  number;
  alpha?:     number;
  type:       'hsla';
}

export type ColorRGBA = {
  red:    number;
  green:  number;
  blue:   number;
  alpha?: number;
  type:   'rgba';
}

export function makeRGBA(red: number, green: number, blue: number, alpha?: number): ColorRGBA {
  return {
    red:   red,
    green: green,
    blue:  blue,
    alpha: alpha,
    type:  'rgba',
  }
}

export function randomRGBA(min: number): ColorType {
  return {
    red:   min + randomInt(255 - min),
    green: min + randomInt(255 - min),
    blue:  min + randomInt(255 - min),
    alpha: 1,
    type:  'rgba',
  }
}

export function randomHSLA(): ColorType {
  return {
    hue:        randomInt(360),
    saturation: randomInt(100),
    lightness:  randomInt(100),
    alpha:      1,
    type:       'hsla',
  }
}

export function colorToCss(col: ColorType): string {
  switch (col.type) {
    case 'hsla':
      if (col.alpha === undefined || col.alpha >= 1) {
        return `hsl(${col.hue}, ${col.saturation}%, ${col.lightness}%)`
      }

      return `hsla(${col.hue}, ${col.saturation}%, ${col.lightness}%, ${col.alpha})`
    case 'rgba':
      if (col.alpha === undefined || col.alpha >= 1) {
        return `rgb(${col.red}, ${col.green}, ${col.blue})`
      }

      return `rgba(${col.red}, ${col.green}, ${col.blue}, ${col.alpha})`
  }
}

const hex = (color: number): string => {
  const str = Math.floor(color).toString(16)

  switch (str.length) {
    case 2:  return str
    case 1:  return '0' + str
    case 0:  return '00'
    default: return '00'
  }
}

const _min = (n1: number, ...nums: number[]): number => {
  if (nums.length === 0) {
    return n1
  }

  let min = n1
  for (const id in nums) {
    if (nums[id] < min) {
      min = nums[id]
    }
  }

  return min
}

const _max = (n1: number, ...nums: number[]): number => {
  if (nums.length === 0) {
    return n1
  }

  let max = n1
  for (const id in nums) {
    if (nums[id] > max) {
      max = nums[id]
    }
  }

  return max
}

export function Hsla2Rgba (hsl: ColorHSLA): ColorRGBA {
  const sat   = hsl.saturation / 100
  const light = hsl.lightness  / 100

  const C = (1 - Math.abs(2 * light - 1)) * sat
  const X = C * (1 - Math.abs( ((hsl.hue / 60) % 2) - 1 ))
  const m = light - C / 2

  let red   = 0
  let green = 0
  let blue  = 0

  if (hsl.hue < 60) {
    red   = C
    green = X
    blue  = 0
  } else if (hsl.hue >= 60 && hsl.hue < 120) {
    red   = X
    green = C
    blue  = 0
  } else if (hsl.hue >= 120 && hsl.hue < 180) {
    red   = 0
    green = C
    blue  = X
  } else if (hsl.hue >= 180 && hsl.hue < 240) {
    red   = 0
    green = X
    blue  = C
  } else if (hsl.hue >= 240 && hsl.hue < 300) {
    red   = X
    green = 0
    blue  = C
  } else if (hsl.hue >= 300 && hsl.hue <= 360) {
    red   = C
    green = 0
    blue  = X
  }

  red   = Math.round( (red   + m) * 255 )
  green = Math.round( (green + m) * 255 )
  blue  = Math.round( (blue  + m) * 255 )

  return {
    red:   red,
    green: green,
    blue:  blue,
    alpha: hsl.alpha,
    type:  'rgba',
  }
}

/**
 *
 * @param rgba {ColorRGBA}
 * @returns {ColorHSLA}
 */
export function RgbaToHsla (rgba: ColorRGBA): ColorHSLA {
  const r1 = rgba.red   / 255
  const g1 = rgba.green / 255
  const b1 = rgba.blue  / 255

  const Cmax  = _max(r1, g1, b1)
  const Cmin  = _min(r1, g1, b1)
  const delta = Cmax - Cmin
  const light = (Cmax + Cmin) / 2

  // HUE Calculation
  let hue = 0

  if (delta !== 0) {
    switch (Cmax) {
      case r1:
        hue = 60 * ( (g1 - b1) / delta ) % 6
        break
      case g1:
        hue = 60 * ( (b1 - r1) / delta ) + 2
        break
      case b1:
        hue = 60 * ( (r1 - g1) / delta ) + 4
        break
    }
  }

  let sat = 0

  if (delta !== 0) {
    sat = ( (delta) / (1 - Math.abs(2 * light - 1)) )
  }

  return {
    hue:        hue,
    lightness:  light,
    saturation: sat,
    alpha:      rgba.alpha,
    type:       'hsla',
  }
}

export function colorToHex(col: ColorType): string {
  // to handle legacy data
  if (typeof col.type === 'undefined') {
    col.type  = 'rgba'
    col.alpha = 1
  }

  switch (col.type) {
    case 'rgba':
      return '#' + hex(col.red) + hex(col.green) + hex(col.blue)
    case 'hsla':
      const rgb = Hsla2Rgba(col)

      return '#' + hex(rgb.red) + hex(rgb.green) + hex(rgb.blue)
  }
}

export function hexToRGBA(hex: string): ColorRGBA {
  if (hex.charAt(0) !== '#') {
    hex = '#' + hex
  }

  const out: ColorRGBA = {
    red:   parseInt(hex.substr(1, 2), 16),
    green: parseInt(hex.substr(3, 2), 16),
    blue:  parseInt(hex.substr(5, 2), 16),
    type:  'rgba',
  }

  if (hex.length === 10) {
    out.alpha = parseInt(hex.substr(7, 2), 16) / 100
  }

  return out
}

export function hexToHSLA(hex: string): ColorHSLA {
  const rgba = hexToRGBA(hex)

  return RgbaToHsla(rgba)
}

/**
 * Normalize a color from the RGB value.
 * RGB values are [0 - 255] and we need the color to be between [0 - 1]
 *
 * @param color
 */
function normalizeColor(color: number): number {
  color /= 255

  if (color <= 0.03928) {
    color /= 12.92
  } else {
    color = Math.pow((color + 0.055) / 1.055, 2.4)
  }

  return color
}

/**
 * Provides relative luminance for the color provided. This ignores
 * the alpha channel when calculating
 *
 * NOTE: We ignore the alpha channel when calculating this otherwise
 * it would get  far, far more complex for dealing with things (probably)
 *
 * @param color {ColorType} The color input
 * @returns {number} The relative luminance defined in WCAG2.0
 * @see https://www.w3.org/TR/WCAG20/#relativeluminancedef
 */
export function relativeLuminance(color: ColorType): number {
  if (color.type === 'hsla') {
    return relativeLuminance(Hsla2Rgba(color))
  }

  // Calculate sRGB
  const red   = normalizeColor(color.red)
  const green = normalizeColor(color.green)
  const blue  = normalizeColor(color.blue)

  return (
    (0.2126 * red  ) +
    (0.7152 * green) +
    (0.0722 * blue )
  )
}

/**
 * Calculates contrash ratio between foreground/background.
 * Ignores alpha channels
 *
 * @param fore {ColorType} Foreground Color (or color1)
 * @param back {ColorType} Background Color (or color2)
 * @returns {number} Contrast Ratio
 * @see https://www.w3.org/TR/WCAG20/#contrast-ratiodef
 */
export function contrastRatio(fore: ColorType, back: ColorType): number {
  fore = fore.type === 'rgba' ? fore : Hsla2Rgba(fore)
  back = back.type === 'rgba' ? back : Hsla2Rgba(back)

  let lum1 = relativeLuminance(fore)
  let lum2 = relativeLuminance(back)

  if (lum1 < lum2) {
    [lum2, lum1] = [lum1, lum2]
  }

  return (
    (lum1 + 0.05) /
    (lum2 + 0.05)
  )
}

export function getTextColor(background: ColorType): ColorType {
  const lum = relativeLuminance(background)

  // console.log('background,', background, 'luminence', lum)

  if (lum > 0.179) {
    return makeRGBA(0, 0, 0, 0.7)
  }

  return makeRGBA(255, 255, 255, 0.7)
}

export function cssToColor(str: string): ColorType {
  if (str.match(/^#[a-f0-9]+/i)) {
    return hexToRGBA(str)
  }

  const match = str.match(/rgba?\((\d+), ?(\d+), ?(\d+)(, ?([\d.]+)|)\)/)

  if (!match || !match.length) {
    return randomRGBA(0)
  }

  return {
    red:   parseInt(match[1]),
    green: parseInt(match[2]),
    blue:  parseInt(match[3]),
    alpha: match[5] === undefined ? undefined : parseFloat(match[5]),
    type:  'rgba',
  }
}
